import Image from '../image';
import { COMMUNITIES_URL } from '../../constants/constants';
import { Link } from 'react-router-dom';
const CardComplete = (props) => {
    const {src, heading, text} = props;
    return (
        <>
            <div className="col-lg-4 mb-3">
            <div className="card card-comm">
                <div className="card-body">
                <Image src={src} className="img-fluid" style={{"min-height": "216px"}} />
                {props.heading === 'EVR Spur Cross' ?(
                <h6 className="card-title"><Link to={COMMUNITIES_URL}>{ heading }</Link></h6>
                ) : <h6 className="card-title">{ heading }</h6>

                }
                <p dangerouslySetInnerHTML={{__html: text}}></p>
                {props.heading === 'EVR Spur Cross' ?(
                <p><Link to={COMMUNITIES_URL} className="link-readmore">Read More</Link></p>
                ) : null
                }

                </div>
            </div>
            </div>
        </>
    );
};

export default CardComplete;
