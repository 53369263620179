import PropTypes from 'prop-types';

const Image = (props) => {
    const {src, style, className} = props;

    return (
        <>
            {
                src &&
                <img src={src} sizes="100vw" style={style} className={className}  {...props} alt=""/>
            }
        </>
    );
};

Image.propTypes = {
    src: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
};

export default Image;