const CardText = (props) => {
    const {text} = props;
    return (
        <>
          <div class="col-lg-4 mg-t-25 mg-lg-t-0 mb-4">
            <div class="card-lifestyle">
              <div class="card-body">
                <p>{ text }</p>
              </div>
            </div>
          </div>
        </>
    );
};

export default CardText;