import { USERCONTACT } from './misc.types';
import api from '../../utils/api';



export const postUserContacts = (data) => {
  return {
    type: USERCONTACT,
    payload: api({
      url: '/contact_application/',
      method: 'POST',
      data: data,
    }),
  };
};

// export const postJoinWaitlist = (data) => {
//   return {
//     type: WAITLIST,
//     payload: api({
//       url: '/join_waitlist/',
//       method: 'POST',
//       data: data,
//     }),
//   };
// };

// export const postScheduleTour = (data) => {
//   return {
//     type: SCHEDULETOUR,
//     payload: api({
//       url: '/schedule_tour/',
//       method: 'POST',
//       data: data,
//     }),
//   };
// };

