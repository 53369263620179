import {ArrowLeftOutlined, ArrowRightOutlined} from '@ant-design/icons'
import {Col, Row, Typography, Radio, Carousel, Card, Button} from 'antd'
import {useState, useRef} from 'react'
import './Hardware.css'


const Hardware = (props) => {
//    const state = { index: 0 };
    const customSlider = useRef(null)
    const data = props.data
    const mobileSize = window.innerWidth < 768
    const hardware_items = data
    const core = hardware_items.filter(item =>(item.hardware_type === 'Core Components'))
    const additional = hardware_items.filter(item =>(item.hardware_type !== 'Core Components'))
    const coreValue = 'Core Components'
    const additionalValue = 'Additional Options'
    const hardware = [
        {
            label: 'Core Components',
            value: 'Core Components',
            items:core
        },
        {
            label: 'Additional Options',
            value: 'Additional Options',
            items: additional
        }
    ]
    const nextSlide = () => {
        customSlider?.current?.next()
    }

    const prevSlide = () => {
        customSlider?.current?.prev()
    }

    const [radioState, setRadioState] = useState(hardware[0].value)

    const changeRadioState = (event) => {
        setRadioState(event.target.value)
        customSlider?.current?.goTo(0)
    }

    console.log(radioState);

    return (
        <Row className={'container hardware-wrapper'} style={{marginBottom: '140px'}}>
            <Col span={24}>
                <Typography.Title level={1} className={'heading-1-size'}
                                  style={{marginTop: '196px', marginBottom: '90px'}}>
                    {props.title}
                </Typography.Title>
            </Col>
            <Col span={24} style={{textAlign: 'center'}}>
                <Radio.Group
                    options={hardware}
                    className={'hardware-switch'}
                    onChange={changeRadioState}
                    defaultValue={radioState}
                    optionType='button'
                    buttonStyle='solid'
                    style={{marginBottom: '90px'}}
                />

                <Carousel
                    dots={false}
                    arrows={false}
                    // autoplay
                    infinite={false}
                    ref={customSlider}
                    slidesToShow={3}
                    slidesToScroll={3}
                    responsive={[
                        {
                            breakpoint: 2140,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            }
                        },
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }
                        }
                    ]}
                >
                    {radioState === coreValue &&
                      core.map(function (item, index) {
                          return (
                            <div className={'hardware-slider'} key={index}>
                                <Card
                                    className={'hardware-item'}
                                    style={{
                                        backgroundImage: `url(${item.image})`,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center center'
                                    }}
                                >
                                    <Typography.Text>{item.name}</Typography.Text>
                                </Card>
                            </div>)
                    })}
                    {radioState === additionalValue &&
                      additional.map(function (item, index) {
                        return (
                          <div key={index} className={'hardware-slider'}>
                            <Card
                              className={'hardware-item'}
                              style={{ backgroundImage: `url(${item.image})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }}
                            >
                              <Typography.Text>{item.name}</Typography.Text>
                            </Card>
                          </div>
                        )
                      })}
                </Carousel>


                <Row justify={!mobileSize ? 'end' : 'center'} gutter={[32, 0]}>
                    <Col>
                        <Button className={'slider-button'} onClick={prevSlide}>
                            <ArrowLeftOutlined/>
                        </Button>
                    </Col>
                    <Col>
                        <Button className={'slider-button'}  onClick={nextSlide}>
                            <ArrowRightOutlined/>
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Hardware
