import { Card, Col, List, Row, Typography, Image, Carousel } from 'antd'
import { useState, useEffect } from 'react'
import './TechnologyPackage.css'

const TechnologyPackage = (props) => {
  const mobileSize = window.innerWidth < 768
  const [mobile, setIsMobile] = useState(mobileSize)
    const data = props.data

  useEffect(() => {
    function handleResize() {
      mobileSize ? setIsMobile(true) : setIsMobile(false)
    }
    window.addEventListener('resize', handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobile, window.innerWidth])

  return (
    <Row className={'container-lg technologies-wrapper'} style={{ marginBottom: '160px' }}>
      <Col span={24}>
        <Typography.Title level={1} className={'heading-1-size'} style={{ marginTop: '196px', marginBottom: '100px' }}>
            {props.title}
        </Typography.Title>
      </Col>
      <Col span={24}>
        {!mobile && (
          <Row justify={'space-between'} gutter={[0, 46]}>
            {data.map(item => (
              <Col key={item.name} span={12} xs={24} sm={24} md={11} xl={11} xxl={12}>
                <Card className={'technology-card'} style={{ width: '100%', height:'100%' }}>
                  <Row align={'middle'}>
                    <Col span={16} xs={24} sm={24} md={16} xl={16} xxl={16}>
                      <Typography.Paragraph className={'body1'}>{item.name}</Typography.Paragraph>
                      <List
                        split={false}
                        style={{ listStyle: '' }}
                        dataSource={item.parentItem}
                        bordered={false}
                        renderItem={item => (
                          <List.Item key={item.parentItem} style={{ justifyContent: 'flex-start', alignItems:'flex-start' }} className={'body2'}>
                            <List.Item.Meta avatar={'\t•'} style={{ maxWidth: '15px' }} />
                              <div>
                            <Typography.Text>{item.parentItem}</Typography.Text>
                            {item?.childItem !== null && (
                              <div className={'children-wrapper'}>
                                {item?.childItem.map(item => (
                                  <Typography.Paragraph key={item.children}>{item.children}</Typography.Paragraph>
                                ))}
                              </div>
                            )}
                                  </div>
                          </List.Item>
                        )}
                      />
                    </Col>
                    <Col span={8} xs={24} sm={24} md={8} xl={8} xxl={8}>
                      <Image preview={false} src={item.image} />
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        )}
        {mobile && (
          <Row className={'mobile-slider-wrapper'}>
            <Carousel className={'mobile-slider'} slidesToShow={1} autoplay draggable={true} dots={false} infinite={false} arrows={false} slidesToScroll={1}>
              {data.map((item, index) => (
                <Col key={index} span={24}>
                  <Card className={'technology-card'}>
                    <Row align={'middle'}>
                      <Col
                        xs={{ span: 24, order: 2 }}
                        sm={{ span: 24, order: 2 }}
                        md={{ span: 16, order: 1 }}
                        xl={{ span: 16, order: 1 }}
                        xxl={{ span: 16, order: 1 }}
                      >
                        <Typography.Paragraph className={'body1'}>{item.name}</Typography.Paragraph>
                        <List
                          split={false}
                          style={{ listStyle: '' }}
                          dataSource={item.list}
                          bordered={false}
                          renderItem={item => (
                            <List.Item key={item.parentItem} style={{ justifyContent: 'flex-start' }} className={'body2'}>
                              <List.Item.Meta avatar={'\t•'} style={{ maxWidth: '15px' }} />
                              <Typography.Text>{item.parentItem}</Typography.Text>
                              {item?.childItem !== null && (
                                <div className={'children-wrapper'}>
                                  {item?.childItem.map(item => (
                                    <Typography.Paragraph key={item.children}>{item.children}</Typography.Paragraph>
                                  ))}
                                </div>
                              )}
                            </List.Item>
                          )}
                        />
                      </Col>
                      <Col
                        xs={{ span: 12, order: 1 }}
                        sm={{ span: 12, order: 1 }}
                        md={{ span: 8, order: 2 }}
                        xl={{ span: 8, order: 2 }}
                        xxl={{ span: 8, order: 2 }}
                      >
                        <Image preview={false} src={item.img} />
                      </Col>
                    </Row>
                  </Card>
                  <span className={'card-index'}>{index + 1 + '/' + data.length}</span>
                </Col>
              ))}
            </Carousel>
          </Row>
        )}
      </Col>
    </Row>
  )
}

export default TechnologyPackage
