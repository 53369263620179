import {Row, Typography, Col} from 'antd'
import {ReactComponent as BannerIcon} from './images/banner.svg'
import './Banner.css'
import {HashLink} from "react-router-hash-link";

const Banner = (props) => {
  const {className, color, title, subtitle} = props;

  return (
      <Row align={'middle'} justify={'center'} style={{backgroundColor: color}}
           className={`banner-wrapper ${className}`}>
          <BannerIcon className={'banner-icon left'}/>
          <BannerIcon className={'banner-icon right'}/>
          <Col span={24}>
              <Typography.Title className={'banner-title white'} level={4}>
                  {title}
              </Typography.Title>
              {subtitle?.length > 0 ?
              <div className={'text-center'}>
                <HashLink to={'/'} className={'link-readmore'}>
                  <Typography.Title className={'white pointer hero-link'} level={5}>
                    {subtitle}
                  </Typography.Title>
                </HashLink>
              </div> : ""}
          </Col>
      </Row>
  )
}

export default Banner
