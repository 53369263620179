import Image from '../image';

const CardImage = (props) => {
    const {src} = props;
    return (
        <>
          <div class="col-lg-4 mg-t-25 mg-lg-t-0">
            <div class="card-lifestyle">
              <div class="card-img">
                <Image src={src} alt="" />
              </div>
            </div>
          </div>
        </>
    );
};

export default CardImage;