import { Col, Row, Typography } from 'antd'
import './InfoLayout.css'


const InfoLayout = (props) =>{

  const mobile = window.innerWidth < 768
    return (
        <Row justify={'space-between'} style={{margin: '240px 0 150px', position: 'relative'}} wrap={false}
             gutter={[0, 32]}>
            {props.side === 'left' ? (
                !mobile ?
                <>
                    <Col style={{marginLeft: '8%'}}>{props.children}</Col>
                    <Col xs={24} sm={24} md={10} xl={12} xxl={12} style={{position: 'relative' ,maxWidth: '700px', marginLeft: '8%'}}>
                        {props.title != null && <Typography.Title className={'about-title'}>{props.title}</Typography.Title>}
                        <div class={'image-block-two'}>
                        <img alt={''} src={props.image} className={'about-img'}/>
                            </div>
                    </Col>
                </>:
                    <>
                    <Col style={{marginLeft: '8%'}}>{props.children}</Col>
                    <Col xs={24} sm={24} md={12} xl={12} xxl={12} className={'image-block'}>
                        {props.title != null && <Typography.Title className={'about-title'}>{props.title}</Typography.Title>}
                        <img alt={''} src={props.image} style={{maxWidth: '700px'}} className={'about-img'}/>
                    </Col>
                </>
            ) : (
                <>
                    <Col xs={24} sm={24} md={12} xl={12} xxl={12} className={'image-block'}>
                        <Typography.Title className={'about-title'}>{props.title}</Typography.Title>
                        <img alt={''} src={props.image} className={'about-img'}/>
                    </Col>
                    <Col style={{marginRight: '8%'}}>{props.children}</Col>
                </>
            )}
        </Row>
    )
}
export { InfoLayout }
