import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {useEffect} from 'react'
import { Helmet } from 'react-helmet';
import Image from '../components/image';
import CardComplete from '../components/boxes/card_complete';
import GetInTouchForm from '../components/getInTouchform'
import { HashLink } from 'react-router-hash-link';
import { ABOUT_URL , COMMUNITIES_URL , CONTACT_URL , CONTACT_SECTION , FLOOR_SECTION } from '../constants/constants';

const Home = () => {
  const siteData = useSelector((state) => state.siteContents?.data);
  const { home_page, about_page, lifestyle_page, contact_page } = siteData || {};
  const communities = home_page.communities_page;
  const communitydetails = communities.communitydetails;

  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.remove("page-contact");
    document.getElementsByTagName('body')[0].classList.remove("menu-show");        
  }, []);  
  
  const community_cards = [];
  for(let i=0;i<communitydetails.length;i++){
    community_cards.push(<CardComplete src={communitydetails[i].box_image} heading={communitydetails[i].main_title} text={communitydetails[i].main_content} />)
  }

  return (
    <>
      <Helmet>
          <title>{home_page.page_title}</title>
          <meta name="description" content={home_page.page_title} />
      </Helmet>    
      
      <section className="banner-home">
        <div className="banner-img"><Image src={home_page.banner_image} alt="" /></div>
        <div className="banner-headline">
          <h2>{home_page.main_title}</h2>
          <p dangerouslySetInnerHTML={{__html: home_page.main_content}}></p>
        </div>
        <div className="banner-home-right">
          <div className="banner-footer">
              <HashLink to={CONTACT_URL + CONTACT_SECTION} className="link-readmore">Contact Us</HashLink>
              <HashLink to={COMMUNITIES_URL + FLOOR_SECTION} className="link-readmore">View Floor Plans</HashLink>
          </div>
        </div>
      </section>

      <section className="section section-home-about">
        <div className="container">
          <div className="row">          
            <div className="col-lg-6">
              <div className="section-img">
                <Image src={about_page.banner_image} />
              </div>
              <h1 className="section-title">About</h1>
            </div>
            <div className="col-lg col-right">
              <p dangerouslySetInnerHTML={{__html: about_page.main_content}}></p>
              <p><Link to={ABOUT_URL} className="link-readmore">Read More</Link></p>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <h1 className="section-title text-center mb-4 mb-lg-5">Communities</h1>

          <div className="row-home">
            <div className="row row-home-comm">
                {community_cards}
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container container-ng">
          <div className="card card-headline">
            <div className="card-body">
              <h1>New destination for exceptional living</h1>
              {/*<p><HashLink to={CONTACT_URL + CONTACT_SECTION} className="link-readmore">Schedule a Tour</HashLink></p>*/}
            </div>
          </div>
        </div>
      </section>

      <section className="section section-home-lifestyle">
        <div className="container">
          <div className="row">
            <div className="col-lg col-left order-2 order-lg-1">
              <h4 className="section-subtitle mb-4">{lifestyle_page.main_title}</h4>
              <p dangerouslySetInnerHTML={{__html: lifestyle_page.main_content}}></p>
              {/*<p><Link to={LIFESTYLE_URL} className="link-readmore">Read More</Link></p>*/}
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="section-img">
                <Image src={lifestyle_page.main_image} alt="" />
              </div>
              <h1 className="section-title">Lifestyle</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="section section-home-contact" id='contact-section'>
        <div className="container container-ng">
          <div className="row g-0">
            <div className="col-lg-6 d-none d-lg-block">
              <div className="section-img">
                <Image src={contact_page.main_image} alt="" />
              </div>              
            </div>
            <GetInTouchForm/>
          </div>
        </div>
      </section>    
    </>
  );
};

export default Home;
