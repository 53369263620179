import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postUserContacts } from '../redux/misc/misc.actions';

const GetInTouchForm = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.userContact);
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [MessageText, setMessageText] = useState('');
  const [focusedOn, setFocusedOn] = useState({});
  const [errors, setErrors] = useState({});
  const {background_color} = props

  const submitContact = (e) => {
    e.preventDefault();

    const data = {
      name: Name,
      email: Email,
      messagetext: MessageText,
    };

    let hasError = false;
    let curr_errors = {};
    function validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    Object.keys(data).forEach((key) => {
      setErrors(curr_errors);
      if (!data[key]) {
        hasError = true;
        curr_errors = {
          ...curr_errors,
          [key]: 'This field is required',
        };
      }
      if (key === 'name') {
        if (data[key].length < 2) {
          curr_errors = {
            ...curr_errors,
            [key]: 'This field should have at least 2 characters',
          };
        }
      }
      if (key === 'messagetext') {
        if (data[key].length < 4) {
          curr_errors = {
            ...curr_errors,
            [key]: 'This field should have at least 4 characters',
          };
        }
      }      
      if (key === 'email') {
        if (!validateEmail(data[key])) {
          curr_errors = {
            ...curr_errors,
            [key]: 'Please Enter Valid Email',
          };
        }
      }      
    });

    if(Object.keys(curr_errors).length !== 0){
      hasError = true;
    }

    setErrors(curr_errors);
    !hasError && dispatch(postUserContacts(data));
  };

  useEffect(() => {
    data?.error && setErrors(data?.error);
  }, [setErrors, data?.error]);

  return (
    <div className="col-lg-6">



      <div className="contact-panel" style={{'background-color': background_color}}>

        {/* {(data?.success === false  || Object.keys(errors).length !== 0 ) && (
          <div className="alert alert-danger" style={{'text-align':'center', 'font-weight':'bold', 'color': 'red'}}>
            <strong>Kindly fix the errors below</strong> <br/>
            {errors?.name && <div style={{'text-align':'left','color': 'red'}}>- Name must be 2 or more characters</div>}            
            {errors?.messagetext && <div style={{'text-align':'left','color': 'red'}}>- Message must be 4 or more characters</div>}
            {errors?.email && <div style={{'text-align':'left','color': 'red'}}>- Ensure email address is valid</div>}
          </div>
        )} */}

        {data?.success && (
          <h4 class="section-subtitle">Submitted Successfully!</h4>
        )}


        {data?.loading && (
          <>
          <h4 class="section-subtitle">Get in touch<br />with our team</h4>
          <div
            className={`form-group required ${
              (Name || focusedOn?.Name) && 'focus'
            }`}
          >
            <input
              onFocus={() => setFocusedOn({ ...focusedOn, Name: true })}
              onBlur={() => setFocusedOn({ ...focusedOn, Name: false })}
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="form-control"
              required
              readOnly={data?.success}
              style = {{
                borderColor: errors?.name  && 'red'
              }}
              placeholder='Enter your Name'
            />
          </div>        

          <div
            className={`form-group mb-3 required ${
              (Email || focusedOn?.Email) && 'focus'
            }`}
          >
            <input
              onFocus={() => setFocusedOn({ ...focusedOn, Email: true })}
              onBlur={() => setFocusedOn({ ...focusedOn, Email: false })}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              className="form-control"
              required
              readOnly={data?.success}
              style = {{
                borderColor: errors?.email  && 'red'
              }}
              placeholder='Enter your Email'            
            />
          </div>

          <div
            className={`form-group mb-3 required ${
              (MessageText || focusedOn?.MessageText) && 'focus'
            }`}
          >
            <textarea
              onFocus={() => setFocusedOn({ ...focusedOn, MessageText: true })}
              onBlur={() => setFocusedOn({ ...focusedOn, MessageText: false })}
              onChange={(e) => setMessageText(e.target.value)}
              type="text"
              className="form-control"
              required
              readOnly={data?.success}
              style = {{
                borderColor: errors?.messagetext  && 'red'
              }}
              placeholder='Type your message here'  
              rows='3'                      
            ></textarea>
          </div>
          {!data?.success && (
            <button onClick={(e) => submitContact(e)} className="btn btn-submit link-readmore">
              Send Message
            </button>
          )}
          </>
        )}
        </div>

    </div>
  );
};

export default GetInTouchForm;