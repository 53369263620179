import Image from '../components/image';

const Page404 = () => {
    return (
        <section className="section-404">
            <div className="container">
                <div className="card-error">
                    <Image src={'https://internetdevels.com/sites/default/files/public/blog_preview/404_page_cover.jpg'}/>
                </div>
                <div>
                    <a href="/" className="btn btn-default">
                    Back Home
                    </a>
                </div>
            </div>
        </section>
    );
};  
export default Page404;