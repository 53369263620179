import {useSelector} from 'react-redux';
import {useEffect} from 'react'
import {Helmet} from 'react-helmet';
import Image from '../components/image';
import CardImage from '../components/boxes/card_image';
import CardText from '../components/boxes/card_text';
import {Link} from 'react-router-dom';
import {CONTACT_URL} from '../constants/constants';

const Lifestyle = () => {
    const lifestyleData = useSelector(
        (state) => state.siteContents?.data?.lifestyle_page
    );

    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.remove("page-contact");
        document.getElementsByTagName('body')[0].classList.remove("menu-show");
    }, []);

    var lifestyle_section = lifestyleData.lifestyle_section.map(function (ld) {
        let tiles = ld.lifestyle_tiles.sort((a, b) => a.id - b.id).map(function (tile) {
            if (tile.text) {
                return (
                    <CardText text={tile.text}/>
                )
            } else {
                return (
                    <CardImage src={tile.image}/>
                )
            }
        })
        return (
            <>
                <section className="section pb-5">
                    <div className="container">
                        <h2 className="section-subtitle text-center mb-3">{ld.heading}</h2>
                        <p className="section-text text-center mb-5" dangerouslySetInnerHTML={{__html: ld.content}}></p>
                        <div className='row'>
                            {tiles}
                        </div>
                    </div>
                </section>
            </>
        )
    })

    return (
        <>
            <Helmet>
                <title>{lifestyleData?.page_title}</title>
                <meta name="description" content={lifestyleData?.page_description}/>
            </Helmet>

            <section className="banner-lifestyle">
                <div className="banner-img"><Image src={lifestyleData?.banner_image} alt="" style={{"left": "0%"}}/>
                </div>
                <div className="banner-left">
                    <h1 className="banner-title">Lifestyle</h1>
                </div>
            </section>

            {lifestyle_section}

            <section className="section py-0">
                <div className="container container-ng">
                    <div className="card card-headline">
                        <div className="card-body">
                            <h1>Come see, touch and feel the<br/>quality of your future apartment.</h1>
                            {/*<p><Link to={CONTACT_URL} className="link-readmore">Schedule a Tour</Link></p>*/}
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
};

export default Lifestyle;
