import {useSelector} from 'react-redux';
import {Helmet} from 'react-helmet';
import ContactSection from "../components/contact";
import React, {useEffect} from 'react';
import AboutSection from './about/heroSection'
import ProductTypes from "./about/productTypes";
import Timeline from "./about/timeline";
import Banner from "./about/Banner";
import HighlightCards from "./about/highlightedCards";

const About = () => {
  const siteData = useSelector(state => state.siteContents?.data);
  const {about_page, contact_page} = siteData || {};

  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.remove("page-about");
    document.getElementsByTagName('body')[0].classList.remove("menu-show");
  }, []);

  console.log(about_page, ' page content');
  return (
    <>
      <Helmet>
        <title>{about_page?.page_title}</title>
        <meta name="description" content={about_page?.page_description}/>
      </Helmet>

      <AboutSection image={about_page?.main_image} title={about_page?.main_title}
                    subtitle={about_page?.banner_text} detail={about_page?.details}
                    cards={about_page?.about_tiles}/>

      <Banner className={'banner-title'}
              color={'#7badd3'}
              title={'Perfect model for your convenient living'}
              subtitle={''}
      />

      <ProductTypes title={about_page?.subtitle}
                    info_layout={about_page?.info_layout}
                    rent_info={about_page?.rent_info}
                    cards={about_page?.cards}
      />

      <Timeline timeline_title={about_page?.timeline_title}
                timeline_subtitle={about_page?.timeline_subtitle}
                timelineInfo={about_page?.timelineInfo}
                timeline={about_page?.timeline}
      />

      <HighlightCards title={about_page?.single_family_title}
                      cards={about_page?.even}
      />

      <ContactSection imgsrc={contact_page.main_image}/>
    </>
  )
}

export default About;
