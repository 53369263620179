import { combineReducers } from 'redux';

import SiteContentReducer from './site_contents/site.reducer';
import { UserContactReducer } from './misc/misc.reducer';

const rootReducer = combineReducers({
  siteContents: SiteContentReducer,
  userContact: UserContactReducer,
//   waitlist: JoinWaitlistReducer,
//   tour : ScheduleTourReducer
});

export default rootReducer;
