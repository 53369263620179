import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TechnologyPackage from "./technology/TechnologyPackage";
import Banner from "./about/Banner";
import Hardware from "./technology/Hardware";
import ContactSection from "../components/contact";

const Technology = () => {
  const siteData = useSelector(state => state.siteContents?.data);
  const {contact_page} = siteData || {};
  const technology_page = useSelector(
    (state) => state.siteContents?.data?.technology_page[0]
  );

  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.remove("page-technology");
    document.getElementsByTagName('body')[0].classList.remove("menu-show");
  }, []);

  if (!technology_page) {
    return false;
  }
  console.log(technology_page)

  console.log(technology_page?.technology_banner[0].className);

  return (
    <>
      <Helmet>
        <title>{technology_page?.page_title}</title>
        <meta name="description" content={technology_page?.page_description}/>
      </Helmet>

      <section className="bannerTec">
        <img src={technology_page?.main_image} alt="" width="" height=""/>
        <div className="contTec">
          <div className="headback">
            <h1>{technology_page?.page_title}</h1>
          </div>
        </div>
      </section>

      {technology_page?.technology_introduction.map((e) => (
          e.is_banner === true ?
            <>
              <section className="secondbanner">
                <img src={e.image} alt="" width="" height=""/>
              </section>
              <section className="smart">
                <p>{e.description} </p>
              </section>
            </>
            :
            <section className="evrSmart">
              <p>{e.description} </p>
              <img src={e.image} alt="" width="" height=""/>
            </section>
        )
      )}

      {technology_page?.package_items.length > 0 ?
        <TechnologyPackage title={technology_page?.package_title} data={technology_page?.package_items}/> : false}

      {technology_page?.technology_banner.length > 0 ?
        <Banner title={technology_page?.technology_banner[0].title}
                subtitle={technology_page?.technology_banner[0].subtitle}
                className={technology_page?.technology_banner[0].className}
                color={technology_page?.technology_banner[0].color}/> : false}

      {technology_page?.hardware_items.length > 0 ?
        <Hardware title={technology_page?.hardware_title} data={technology_page?.hardware_items}/> : false}

      <ContactSection imgsrc={contact_page.main_image} background={technology_page?.technology_banner[0].color}/>
    </>
  );
}
export default Technology