import {Card, Carousel, Col, Row, Typography} from 'antd'
import './HighlightedCards.css'
import {useRef, useState} from 'react'

const HighlightCards = (props) => {
    const mobile = window.innerWidth < 768
    const mobileSlider = useRef(null)
    const [slideIndex, setSlideIndex] = useState(0)

    return (
        <Row className={'highlighted-cards container'} justify={'center'}>
            <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                <Typography.Title level={4} className={'title1'}>
                    {props.title}
                </Typography.Title>
            </Col>
            <Col span={24}>
                {!mobile && (
                    <Row justify={'space-between'} gutter={[40, 40]}>
                        {props.cards.map((item, index) => {
                            return (
                                <Col sm={24} md={11} xl={7} xxl={8} span={8} key={index}>
                                    <Card className={'highlighted-card'}>
                                        <Typography.Text>
                                            {
                                                item.text.split("',").count % 2 !== 0 ?
                                                item.text.split("',").map((items, index) =>
                                                    index % 2 === 0 ? (<span key={index}>{items.replace("'", "")} </span>) : (
                                                        <span className={'highlighted'} key={index}>{items.replace("'", "")}{' '}</span>)
                                                )
                                                :
                                                item.text.split("',").map((items, index) =>
                                                        index % 2 === 0 ? (<span className={'highlighted'} key={index}>{items.replace("'", "")}{' '}</span>) : (<span key={index}>{items.replace("'", "")} </span>),
                                                )}
                                        </Typography.Text>
                                    </Card>
                                </Col>
                            )
                        })}
                    </Row>
                )}
                {mobile && (
                    <Row justify={'space-between'} gutter={[0, 0]}>
                        <Carousel
                            className={'mobile-slider'}
                            afterChange={() => {
                                return setSlideIndex(mobileSlider?.current?.innerSlider.state.currentSlide)
                            }}
                            ref={mobileSlider}
                            slidesToShow={1}
                            autoplay
                            draggable={true}
                            dots={false}
                            infinite={false}
                            arrows={false}
                            slidesToScroll={1}
                            responsive={[
                                {
                                    breakpoint: 1740,
                                    settings: {
                                        slidesToShow: 3,
                                        slidesToScroll: 3,
                                    }
                                },
                                {
                                    breakpoint: 1200,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 2,
                                    }
                                },
                                {
                                    breakpoint: 600,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                    }
                                }
                            ]}
                        >
                            {props.cards.map((item, index) => {
                                return (
                                    <Card className={'highlighted-card'}>
                                        <Typography.Text>
                                            {
                                                item.text.split("',").count % 2 !== 0 ?
                                                item.text.split("',").map((items, index) =>
                                                    index % 2 === 0 ? (<span key={index}>{items.replace("'", "")} </span>) : (
                                                        <span className={'highlighted'} key={index}>{items.replace("'", "")}{' '}</span>)
                                                )
                                                :
                                                item.text.split("',").map((items, index) =>
                                                        index % 2 === 0 ? (<span className={'highlighted'} key={index}>{items.replace("'", "")}{' '}</span>) : (<span key={index}>{items.replace("'", "")} </span>),
                                                )}
                                        </Typography.Text>
                                    </Card>
                                )
                            })}
                        </Carousel>
                        <span
                            className={'card-index'}>{slideIndex + 1 + '/' + mobileSlider?.current?.innerSlider?.state.slideCount || 0}</span>
                    </Row>
                )}
            </Col>
        </Row>
    )
}

export default HighlightCards