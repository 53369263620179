import './Timeline.css'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Row, Col, Typography, Carousel, Button } from 'antd'
import { useRef } from 'react'


const Timeline = (props) => {
  const { timeline_title, timeline_subtitle, timelineInfo, timeline} = props;

  const mobile = window.innerWidth < 768
  const customSlider = useRef(null)
  const nextSlide = () => {
    console.log(customSlider.current)
    customSlider?.current?.next()
  }

  const prevSlide = () => {
    customSlider?.current?.prev()
  }
  return (
    <Row className={'timeline container'}>
      <Col span={24}>
        <Row className={'timeline-title'} gutter={[0, 32]} justify={'center'}>
          <Col span={24} className={'title-content'}>
            <Typography.Title level={1} className={'heading-1-size'}>
              {timeline_title}
            </Typography.Title>
            <Typography.Paragraph className={'body1'}>{timeline_subtitle}</Typography.Paragraph>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        {timeline.length > 0 ?
        <Row className={'timeline-wrapper'}>
          <Col span={24}>
            <Carousel
              ref={customSlider}
              draggable={true}
              autoplay={false}
              dots={false}
              arrows={false}
              slidesToShow={!mobile ? 2.3 : 1}
              infinite={false}

                            responsive={[
                                {
                                    breakpoint: 2140,
                                    settings: {
                                        slidesToShow: 3,
                                        slidesToScroll: 3,
                                    }
                                },
                                {
                                    breakpoint: 1200,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 2,
                                    }
                                },
                                {
                                    breakpoint: 600,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                    }
                                }
                            ]}
            >
              {timeline?.map((item, index) => {
                return (
                  <div key={index} className={'timeline-item'}>
                    <Typography.Text className={'timeline-year'}>
                      <Typography.Paragraph className={'body2 year'}>{item?.year.toString()}</Typography.Paragraph>
                      <Typography.Paragraph className={'body2 title'}>{item?.title}</Typography.Paragraph>
                    </Typography.Text>
                    <Typography.Paragraph className={'timeline-text'}>{item?.description}</Typography.Paragraph>
                  </div>
                )
              })}
            </Carousel>
          </Col>
        </Row>:false}
        <Row justify={'space-between'} className={'timeline-controls'} gutter={[0, 24]}>
          <Col
            xs={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            md={{ span: 12, order: 1 }}
            xl={{ span: 12, order: 1 }}
            xxl={{ span: 12, order: 1 }}
            className={'information-source'}
          >
            <Typography.Paragraph>{timelineInfo}</Typography.Paragraph>
          </Col>
        {timeline.length > 0 ?
          <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={12} xl={12} xxl={12}>
            <Row justify={'end'} gutter={[32, 0]}>
              <Col span={1.5}>
                <Button className={'slider-button'} onClick={prevSlide}>
                  <ArrowLeftOutlined />
                </Button>
              </Col>
              <Col span={1.5}>
                <Button className={'slider-button'} onClick={nextSlide}>
                  <ArrowRightOutlined />
                </Button>
              </Col>
            </Row>
          </Col>:false}
        </Row>
      </Col>
    </Row>
  )
}

export default Timeline
