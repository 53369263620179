import Image from '../image';
import { Link } from 'react-router-dom'

const CardImageHeading = (props) => {
    const {src, heading, id, label, is_coming_soon} = props;
    if(is_coming_soon === false){
        return (
            <>
                <div class="col-lg-4 mt-5">
                    <Link to={'/community/' + id}>
                        <div class="card card-commu">
                            <label>{label}</label>
                            <div class="card-img">
                                <Image src={src} alt="" />
                            </div>
                            <div class="card-footer">
                                { heading }
                            </div>
                        </div>
                    </Link>
                </div>
            </>
        );
    }
    return (
        <>
            <div class="col-lg-4 mt-5">
                <div class="card card-commu">
                    <label>{label}</label>
                    <div class="card-img">
                        <Image src={src} alt="" />
                    </div>
                    <h1>Coming Soon</h1>
                    <div class="card-footer">
                        { heading }
                    </div>
                </div>
            </div>
        </>
    );


};

export default CardImageHeading;