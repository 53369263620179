import {useSelector} from 'react-redux';

import {Link} from 'react-router-dom';
import Image from './image';
import FacebookFillIcon from 'remixicon-react/FacebookFillIcon';
import InstagramFillIcon from 'remixicon-react/InstagramFillIcon';
import YoutubeFillIcon from 'remixicon-react/YoutubeFillIcon';
import TwitterFillIcon from 'remixicon-react/TwitterFillIcon';
import MapPin2FillIcon from 'remixicon-react/MapPin2FillIcon';
import PhoneFillIcon from 'remixicon-react/PhoneFillIcon';
import { HashLink } from 'react-router-hash-link';
import { COMMUNITIES_URL , CONTACT_URL } from '../constants/constants';

const Footer = (props) => {
    const { contents } = props;
    const contact_details = contents.data.contact_section;
    const isLoaded = useSelector(state => state.siteContents?.success);
    const siteData = useSelector((state) => state.siteContents?.data);
    return (
        <>
            {isLoaded &&

                <footer className="footer">
                    <div className="container">
                        <div className="row mb-5">
                        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <Link to="/" className="footer-logo"><Image src={siteData.logos[0]?.image || siteData.contact_page.logo} alt="" /></Link>
                        </div>
                        <div className="col-lg-3  col-md-6 col-sm-6 col-xs-12 mg-t-40 mg-lg-t-0">
                            <ul className="list-unstyled mb-0">
                            <li>
                                <a href={contact_details.link_to_map} rel="noreferrer" target='_blank' className='black-link' style={{display:'inline-flex'}}>
                                    <MapPin2FillIcon />
                                    <p>
                                        <p className='ml-2' dangerouslySetInnerHTML={{__html: contact_details.address_line}}></p>
                                    </p>
                                </a>
                            </li>
                            <li>
                                <PhoneFillIcon/>
                                <p className='ml-2 black-link' dangerouslySetInnerHTML={{__html: contact_details.phone}}></p>
                            </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mg-t-40 mg-lg-t-0">
                            <nav className="nav nav-links">
                                <HashLink to="/communities#section-floor">View Floor Plans</HashLink>
                                <Link to={COMMUNITIES_URL}>Communities</Link>
                                <Link to={CONTACT_URL}>Contact</Link>
                            </nav>
                        </div>
                        <div className="col-lg-3  col-md-12 col-sm-12 col-xs-12 mg-t-40 mg-lg-t-0" style={{'padding':'0px'}}>
                            <nav className="nav nav-icon">
                            <a href={contact_details.facebook_url}><FacebookFillIcon /></a>
                            <a href={contact_details.instagram_url}><InstagramFillIcon /></a>
                            <a href={contact_details.youtube_url}><YoutubeFillIcon /></a>
                            <a href={contact_details.twitter_url}><TwitterFillIcon /></a>
                            </nav>
                        </div>
                        </div>
                        <div className="footer-bottom">
                        <span>By El Dorado Holdings, Inc. EVR 2021 &copy;</span>

                        <div className="d-flex align-items-center">
                            <a href="https://evr.dwell.io/templates/home.html">Privacy Policy</a>
                            <span className="mx-2">|</span>
                            <a href="https://evr.dwell.io/templates/home.html">Terms of Use</a>
                        </div>
                        </div>
                    </div>
                </footer>
            }
        </>
    );
};

export default Footer;
