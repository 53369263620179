import {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {getSiteContent} from '../redux/site_contents/site.actions';
import Image from './image';
import {HashLink} from 'react-router-hash-link'
import {
    ABOUT_URL,
    COMMUNITIES_URL,
    LIFESTYLE_URL,
    CONTACT_URL,
    FLOOR_SECTION,
    HOME_URL,
    CONTACT_SECTION,
    TECHNOLOGY_URL
} from '../constants/constants';

const NavBar = (props) => {
    const dispatch = useDispatch();
    const currLocation = useLocation();
    const [showMenu, setShowMenu] = useState(false);
    const isLoaded = useSelector(state => state.siteContents?.success);
    const wrapperRef = useRef(null);
    const isVisible = useState(true);
    const siteData = useSelector((state) => state.siteContents?.data);

    useEffect(() => {
        dispatch(getSiteContent());
    }, [dispatch]);

    const handleClick = (e) => {
        document.getElementsByTagName('body')[0].classList.toggle("menu-show");
        document.addEventListener("click", handleClickOutside, false);
        return () => {
          document.removeEventListener("click", handleClickOutside, false);
        };
    };

    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            document.getElementsByTagName('body')[0].classList.remove("menu-show");
        }
      };

    console.log(currLocation.pathname)

    return (
        <>
            {
                isVisible &&
                (
                isLoaded &&
                <header ref={wrapperRef}>
                    <div id="headerMenu" className="header-menu" onClick={(e) => handleClick(e)}>
                        <p>Menu</p>
                        <p>Close</p>
                        <span></span>
                    </div>

                    <Link to={HOME_URL} className="header-logo">
                        <Image src={siteData.logos[0]?.image || siteData.contact_page.logo} />
                    </Link>

                    <div
                        className={`menu  ${
                            showMenu && 'show-nav'
                        }`}
                    >
                        <div className="menu-body">
                            <nav className="nav">
                                <Link onClick={() => setShowMenu(false)}
                                      to={HOME_URL}
                                      className={`nav-link ${
                                        (currLocation.pathname === HOME_URL) && 'active'
                                    }`}>Home</Link>
                                <Link
                                    to={ABOUT_URL}
                                    onClick={() => setShowMenu(false)}
                                    className={`nav-link ${
                                        (currLocation.pathname === ABOUT_URL || currLocation.pathname.startsWith(ABOUT_URL)) && 'active'
                                    }`}
                                >
                                    Build-For-Rent
                                </Link>
                                <Link
                                    to={COMMUNITIES_URL}
                                    onClick={() => setShowMenu(false)}
                                    className={`nav-link ${
                                        (currLocation.pathname === COMMUNITIES_URL || currLocation.pathname.startsWith(COMMUNITIES_URL)) && 'active'
                                    }`}
                                >
                                    Communities
                                </Link>
                                <Link
                                    to={LIFESTYLE_URL}
                                    onClick={() => setShowMenu(false)}
                                    className={`nav-link ${
                                        (currLocation.pathname === LIFESTYLE_URL || currLocation.pathname.startsWith(LIFESTYLE_URL)) && 'active'
                                    }`}
                                >
                                    Lifestyle
                                </Link>
                                <Link
                                    to={TECHNOLOGY_URL}
                                    onClick={() => setShowMenu(false)}
                                    className={`nav-link ${
                                        (currLocation.pathname === TECHNOLOGY_URL || currLocation.pathname.startsWith(TECHNOLOGY_URL)) && 'active'
                                    }`}
                                >
                                    Technology
                                </Link>
                                {/* <Link
                                    to={TEAM_URL}
                                    onClick={() => setShowMenu(false)}
                                    className={`nav-link ${
                                        (currLocation.pathname === TEAM_URL || currLocation.pathname.startsWith(TEAM_URL)) && 'active'
                                    }`}
                                >
                                    Team
                                </Link>                                                                                                 */}
                                <Link
                                    to={CONTACT_URL}
                                    onClick={() => setShowMenu(false)}
                                    className={`nav-link ${
                                        (currLocation.pathname === CONTACT_URL || currLocation.pathname.startsWith(CONTACT_URL)) && 'active'
                                    }`}
                                >
                                    Contact
                                </Link>
                            </nav>
                        </div>
                        <div className="menu-footer">
                            <HashLink to={CONTACT_URL + CONTACT_SECTION} onClick={() => setShowMenu(false)} class="link-readmore">Contact Us</HashLink>
                            <HashLink to={COMMUNITIES_URL + FLOOR_SECTION} class="link-readmore">View Floor Plans</HashLink>
                        </div>
                    </div>
                </header>
                )
            }
        </>
    );
};

NavBar.propTypes = {
    scroll: PropTypes.any,

};


export default NavBar;
