import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Image from '../components/image';
import CardImageHeading from '../components/boxes/card_image_heading';
import GetInTouchForm from '../components/getInTouchform';
import { HashLink } from 'react-router-hash-link';
import { useEffect } from 'react';
import { COMMUNITIES_URL , CONTACT_URL , CONTACT_SECTION , FLOOR_SECTION } from '../constants/constants';

const Lifestyle = () => {
    const communityData = useSelector(
        (state) => state.siteContents?.data?.home_page.communities_page
    );
    const aboutContent = useSelector(state => state.siteContents?.data?.about_page);

    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.remove("page-contact");
        document.getElementsByTagName('body')[0].classList.remove("menu-show");            
      }, []);    

    let card_arr = []
    for (let i = 0; i < communityData.communitydetails.length; i++) {
        card_arr.push(<CardImageHeading label={i + 1}
            id={communityData.communitydetails[i].id}
            is_coming_soon={communityData.communitydetails[i].is_coming_soon}
            src={communityData.communitydetails[i].box_image}
            heading={communityData.communitydetails[i].box_text} />
        );
    }

    return (
        <>
            <Helmet>
                <title>{communityData?.page_title}</title>
                <meta name="description" content={communityData?.page_description} />
            </Helmet>
            <div className="banner-comm">
                <div className="media">
                    <div className="media-body">
                        <h1>{communityData?.main_title}</h1>
                        <p dangerouslySetInnerHTML={{ __html: communityData.main_content }}></p>
                    </div>
                    <div className="media-right d-none d-lg-flex">
                        <HashLink to={CONTACT_URL + CONTACT_SECTION} class="link-readmore">Contact Us</HashLink>
                        <HashLink to={COMMUNITIES_URL + FLOOR_SECTION} class="link-readmore">View Floor Plans</HashLink>
                    </div>
                </div>
            </div>

            <section className="section section-comm">
                <div className="container">
                    <div className="row">
                        {card_arr}
                    </div>
                </div>
            </section>


            <section className="section section-media">
                <div className="media">
                    <div className="media-body">
                        <h4>{communityData.section_heading} </h4>
                        <p>{communityData.section_text}</p>
                    </div>
                    <div className="media-right">
                        {/* <HashLink to={CONTACT_URL + CONTACT_SECTION}>Contact Us</HashLink>
                        <HashLink to={COMMUNITIES_URL + FLOOR_SECTION}>View Floor Plans</HashLink> */}
                    </div>
                </div>
            </section>

            <section className="section section-comm-map">
                <div className="container">
                    <div className="section-img">
                        <Image src={communityData.image} alt="" />
                    </div>
                </div>
            </section>
            
            <section className="section section-amenities">
                <div className="container">
                    <h1 className="section-title text-center mb-5">Amenities</h1>
                    <nav className="nav nav-section mb-5">
                        <a href="#tabA1" data-bs-toggle="tab" className="nav-link active">Communities perks</a>
                        <a href="#tabA2" data-bs-toggle="tab" className="nav-link">In-home features</a>
                        <a href="#tabA3" data-bs-toggle="tab" className="nav-link">Smart home features</a>
                    </nav>

                    <div className="tab-content">
                        <div id="tabA1" className="tab-pane fade active show">
                            <div className="row">
                                {communityData.community_amenities.filter(e => e.amenity_type === 'Perks').map((e,i)=>{
                                        return(
                                            <div className={"col-lg-3 mg-t-10 mg-lg-t-30"}>
                                                <div className="card card-amenities">
                                                    <div className="card-body">{e.text}</div>
                                                </div>
                                            </div>
                                        )
                                })}
                            </div>
                        </div>
                        <div id="tabA2" className="tab-pane fade">
                            <div className="row">
                                {communityData.community_amenities.filter(e => e.amenity_type === 'In-Home Feature').map((e,i)=>{
                                    return(
                                        <div className={"col-lg-3 mg-t-10 mg-lg-t-30"}>
                                            <div className="card card-amenities">
                                                <div className="card-body">{e.text}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div id="tabA3" className="tab-pane fade">
                            <div className="row">
                                {communityData.community_amenities.filter(e => e.amenity_type === 'Smart Home Features').map((e,i)=>{                            
                                    return(<div className={"col-lg-3 mg-t-10 mg-lg-t-30"}>
                                        <div className="card card-amenities">
                                            <div className="card-body">Smart home app, customizable scenes</div>
                                        </div>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section section-commu">
                <div className="container container-ng">
                    <div className="card card-headline">
                        <div className="card-body">
                            <h1>Come see, touch and feel the<br />quality of your future apartment.</h1>
                            {/*<p><HashLink to={CONTACT_URL + CONTACT_SECTION} className="link-readmore">Schedule a Tour</HashLink></p>*/}
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-floor" id='section-floor'>
                <div className="container">
                    <h1 className="section-title text-center mb-2 mb-lg-4">Floor plans</h1>
                    <p className="section-text text-center">We have a wide range of offers for you.</p>

                    <hr className="op-0 mg-y-25 mg-lg-y-50" />
                    <div className="row">
                        {communityData.community_floorplan.map(function(e,i){
                            return(
                                <div className={"col-md-6 " + ((i%2===0) ? ("mg-t-20 mg-md-t-" + (30*(i/2))) : ("mg-t-20 mg-md-t-" + (30*(i-1)/2)))}>
                                    <div className={"card card-floor " + ((i%2!==0) ? 'transy' : '') }>
                                        <div className="section-img"><Image src={e.image} alt="" /></div>

                                        <div className="card-footer">
                                            <h3>{e.hover_text}</h3>
                                            {/*<HashLink to="#sectioncontact" className="link-readmore">Ask Information</HashLink>*/}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <section className="section section-amenities">
                <div className="container">
                    <h1 className="section-title text-center mb-4">Neighborhood</h1>
                    <p className="section-text text-center mb-5">Social. Scenic. Sophisticated. You’ll find all three at EVR Spur Cross in Queen Creek, Arizona.</p>
                    <nav className="nav nav-section mb-5">
                        <a href="#tabB1" data-bs-toggle="tab" className="nav-link active">Eat</a>
                        <a href="#tabB2" data-bs-toggle="tab" className="nav-link">Shop</a>
                        <a href="#tabB3" data-bs-toggle="tab" className="nav-link">Play</a>
                    </nav>

                    <div className="tab-content">
                        <div id="tabB1" className="tab-pane fade active show">
                            <div className="row">
                                {communityData.community_neighborhood.filter(e => e.neighborhood_type === 'Eat').map((e)=>{
                                    return(
                                        <div className="col-lg-4 mb-3">
                                            <div className="card card-commu">
                                                <div className="card-img"><Image src={e.image} alt="" /></div>
                                                <div className="card-footer">{e.text}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div id="tabB2" className="tab-pane fade">
                            <div className="row">
                                {communityData.community_neighborhood.filter(e => e.neighborhood_type === 'Shop').map((e)=>{
                                    return(
                                        <div className="col-lg-4 mb-3">
                                            <div className="card card-commu">
                                                <div className="card-img"><Image src={e.image} alt="" /></div>
                                                <div className="card-footer">{e.text}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div id="tabB3" className="tab-pane fade">
                            <div className="row">
                                {communityData.community_neighborhood.filter(e => e.neighborhood_type === 'Play').map((e)=>{
                                    return(
                                        <div className="col-lg-4 mb-3">
                                            <div className="card card-commu">
                                                <div className="card-img"><Image src={e.image} alt="" /></div>
                                                <div className="card-footer">{e.text}</div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <section className="section section-home-contact" id="sectioncontact">
                <div className="container container-ng">
                    <div className="row g-0">
                        <div className="col-lg-6 d-none d-lg-block">
                            <div className="section-img">
                                <Image src={aboutContent.main_image} alt="" />
                            </div>
                        </div>
                        <GetInTouchForm background_color={'#1B5633'} />
                    </div>
                </div>
            </section>


        </>
    );
};

export default Lifestyle;
