import {Col, Row, Typography, Image, Card} from 'antd'
import './HeroSection.css'

const AboutHero = (props) => {

  return (
    <Row className={'hero-section about'}>
      <Col span={24}>
        <Row className={'title-wrapper'}>
          <Col xs={24} sm={24} md={16} xl={16} xxl={16}>
            <Typography.Title className={'heading-3-size'} level={3}>
              {props.title}
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={8} xl={8} xxl={8} className={'subtitle-wrapper'}>
            <Typography.Paragraph className={'subtitle'}>{props.subtitle}</Typography.Paragraph>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={16} xl={16} xxl={16} className={'image-block'}>
        <Image src={props.image} preview={false}/>
      </Col>
      <Col span={24} className={'project-details'}>
        <Typography.Paragraph className={'body1'}>
          <div dangerouslySetInnerHTML={{__html: props.detail}}></div>
        </Typography.Paragraph>
      </Col>
      <Col className={'advantages'} span={24}>
        <Row justify={'space-between'} className={'container'} gutter={[0, 32]}>
          {props.cards.map((item, index) => (
            <Col key={index} xs={24} sm={24} md={11} xl={7} xxl={8} span={8}>
              <Card className={'advantage-card'}>
                <Image src={item.tile_image} className={'hero-card-image'} preview={false}/>
                <Typography.Paragraph className={'highlight'}>{item.tile_text}</Typography.Paragraph>
              </Card>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>)
}
export default AboutHero