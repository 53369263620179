import { Card, Col, Row, Typography, Image, Carousel } from 'antd'
import { InfoLayout } from './InfoLayout'
import './ProductTypes.css'
//import {HashLink} from "react-router-hash-link";
//import {LIFESTYLE_URL} from "../../constants/constants";
//import { NavLink } from 'react-router-dom'

const ProductTypes = (props) => {
  const {rent_info, cards} = props;
  const mobile = window.innerWidth < 768;

  return (
    <Row className={'product-types container'}>

      {rent_info.length > 0 ?
      <Col span={24} className={'rent-info container'}>
        <Typography.Title level={1} className={'heading-1-size'}>
          {rent_info[0].title}
        </Typography.Title>
        <InfoLayout side={'right'} title={null} image={props?.rent_info[0].image}>
          {rent_info[0].text.map((item, index) => (
            <Typography.Paragraph className={`subtitle subtitle${index}`} key={index}>
              <p dangerouslySetInnerHTML={{__html: item.text}}></p>
            </Typography.Paragraph>
          ))}
        </InfoLayout>
      </Col>: false}

      {cards.length > 0 ?
      <Col span={24} className={'product-cards container'}>
        <Row className={'cards-wrapper'} justify={'space-between'} gutter={[{ sm: 20, md: 20, xl: 40, xxl: 40 }, 64]}>
          {!mobile &&
            cards.map((item, index) => (
              <Col sm={12} md={12} xl={8} xxl={8} key={index}>
                <Card className={'product-card'}>
                  <Image preview={false} src={item.image} />
                  <Typography.Paragraph className={'title2'}>{item.text}</Typography.Paragraph>
                  <Typography.Paragraph className={'body2 size'}>{item.size}/acre</Typography.Paragraph>
                  <Typography.Paragraph className={'body2 description'}>{item.description}</Typography.Paragraph>
                </Card>
              </Col>
            ))}
          {mobile && (
            <Carousel className={'mobile-slider'} slidesToShow={1} draggable={true} dots={false} infinite={false} arrows={false} slidesToScroll={1}>
              {cards.map((item, index) => (
                <Col sm={24} md={12} xl={8} xxl={8} key={index}>
                  <Card className={'product-card'}>
                    <Image preview={false} src={item.image} />
                    <Typography.Paragraph className={'title2'}>{item.text}</Typography.Paragraph>
                    <Typography.Paragraph className={'body2 size'}>{item.size}/acre</Typography.Paragraph>
                    <Typography.Paragraph className={'body2 description'}>{item.description}</Typography.Paragraph>
                  </Card>
                  <span className={'card-index'}>{index + 1 + '/' + cards.length}</span>
                </Col>
              ))}
            </Carousel>
          )}
        </Row>
      </Col>:false}
    </Row>
  )
}

export default ProductTypes
