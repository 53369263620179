import Image from './image';
import GetInTouchform from "./getInTouchform";

const ContactSection = (props) => {
    const {imgsrc, background} = props;
    return (
        <>
            <section class="section section-home-contact">
                <div class="container-ng">
                    <div class="row g-0">
                        <div class="col-lg-6 d-none d-lg-block">
                            <div class="section-img">
                                <Image src={imgsrc} alt="" />
                            </div>
                        </div>
                        <GetInTouchform background_color={background}/>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactSection;


