import './App.css';
import './main.css'
import 'antd/dist/antd.css';
import NavBar from './components/navbar';
import Footer from './components/footer';

import About from './pages/about';
import './scss/style.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import Contact from './pages/contacts';
import Communities from './pages/communities';
import Lifestyle from './pages/lifestyle';
import Home from './pages/home';
import Page404 from './pages/page404';
// import House from './pages/house';
// import Team from './pages/team';
// import UserEntryPage from './pages/userEntry';
import {useSelector} from 'react-redux';
import { Helmet } from 'react-helmet';
import Technology from "./pages/technology";

const ScrollToTop = () => {
  let location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return null;
};

const App = () => {
  const [navOnScroll, setNavOnScroll] = useState(false);
  // console.log(state?.siteContents)
  const contents = useSelector(state => state?.siteContents);
  const siteData = useSelector((state) => state.siteContents?.data);


  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset > 60) {
        setNavOnScroll(true);
      } else {
        setNavOnScroll(false);
      }
    };
  }, []);
  return (
    <>
    {
          contents.success &&
    <Helmet>
      <link rel="icon" href={siteData.contact_page.favicon} />
      <link rel="apple-touch-icon" href={siteData.contact_page.favicon} />    
    </Helmet>        
    }
    <div className="App">
      <Router>
        <ScrollToTop />
        <NavBar scroll={navOnScroll} />        
        {
          contents.success &&
          <Switch>
            <Route path="/404">
              <Page404/>
            </Route>            
            <Route path="/contacts">
              <Contact/>
            </Route>
            <Route path="/lifestyle">
              <Lifestyle/>
            </Route>            
            <Route path="/about">
              <About />
            </Route>
            <Route path="/technology">
              <Technology />
            </Route>
            <Route path="/communities">
              <Communities />
            </Route>
            <Route path="/">
              <Home/>
            </Route>
          </Switch>
        }
        {
          contents.success &&
          <Footer contents={contents} />
        }
      </Router>
    </div>
    </>
  );
};

export default App;
