import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import React, { useEffect } from 'react';
import GetInTouchForm from "../components/getInTouchform";
//import Image from "../components/image";

const Contact = () => {
  const contactData = useSelector(
    (state) => state.siteContents?.data?.contact_page
  );
  const contact_details = useSelector((state) => state.siteContents?.data).contact_section;
  const email = "mailto:" + contact_details.email;
  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.toggle("page-contact");
    document.getElementsByTagName('body')[0].classList.remove("menu-show");    
  }, []);

  return (
    <>
      <Helmet>
          <title>{contactData?.page_title}</title>
          <meta name="description" content={contactData?.page_description} />
      </Helmet>

      <section className="banner-contact">
        <div className="banner-img">
          <img src={contactData?.banner_image} alt="" width="" height=""/>
        </div>
        <div className="banner-left">
          <div className="banner-title">
            <h1 className="banner-title">Contact</h1>
          </div>
        </div>
      </section>

      {/*<section className="banner-contact">*/}
      {/*  <div className="banner-img">*/}
      {/*    <Image src={contactData?.banner_image} alt="" />*/}
      {/*  </div>*/}
      {/*  <div className="banner-left">*/}
      {/*    <h1 className="banner-title">Contact</h1>*/}
      {/*  </div>*/}
      {/*</section>*/}

      <section className="section section-home-contact" id='section-contact'>
        <div className="container-ng">
          <div className="row g-0">
            <div className="col-lg-6">
              <div class='contact-panel'>
                  <div className="contact-group">
                    <label>Phone</label>
                    <h6 className='ml-2 white-link' dangerouslySetInnerHTML={{__html: contact_details.phone}}></h6>
                  </div>
                  <div className="contact-group">
                    <label>Email</label>
                        <h6 className='ml-2'><a href={email} className='white-link'>{contact_details.email}</a></h6>
                  </div>
                  <div className="contact-group">
                    <label>Address</label>
                      <a href={contact_details.link_to_map} target='_blank' className='white-link' style={{display:'inline-flex'}} rel="noreferrer">
                      <h6 className='ml-2' dangerouslySetInnerHTML={{__html: contact_details.address_line}}></h6>
                    </a>                
                  </div>
              </div>
            </div>
            <GetInTouchForm/>
          </div>
        </div>
      </section>

    </>
  );
};

export default Contact;
